import * as chains from './chains';

// If you add coins for a new network, make sure Weth address (for the router you are using) is the first entry

const XRPLcoins = [
  {
    name: "BNB",
    abbr: "BNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", // Weth address is fetched from the router
  },
  {
    name: "Babylon Token",
    abbr: "XBT",
    address: "0xe329102DA0E7E135656CD72CDc983c81f27CB5B6",
  },
  {
    name: "USDT",
    abbr: "USDT",
    address: "0x55d398326f99059fF775485246999027B3197955",
  },
  {
    name: "BUSD",
    abbr: "BUSD",
    address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  {
    name: "MyNeighborAlice",
    abbr: "ALICE",
    address: "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
  },
]

const COINS = new Map();
COINS.set(chains.ChainId.XRPL, XRPLcoins)
export default COINS
